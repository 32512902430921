import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({title, description, keywords}) => {
    return (
        <Helmet>
            <title> {title}</title>
            <meta name='description' content={description}/>
            <meta name='keywords' content={keywords}/>
        </Helmet>
    )
}

<Meta title={"Pixagan: Making Education and Fun and Interactive Journey"} description={"Pixagan provides multiple tools and platforms to make learning fun and interactive. Our ecosystem consists of the TeachYaar platform to create interactive and dynamics learning notebooks, Qwz4u an english quiz platform to prepare for tests and exams and Tyzlle a wordle like word game to sharpen your brain."} keywords={"EdTech, education, technology, AI, teachyaar, tyzlle, qwz4u, tools for learning, english tests"} />


Meta.defaultProps = {
    title: 'Pixagan: Making Education and Fun and Interactive Journey',
    description: "Pixagan provides multiple tools and platforms to make learning fun and interactive. Our ecosystem consists of the TeachYaar platform to create interactive and dynamics learning notebooks, Qwz4u an english quiz platform to prepare for tests and exams and Tyzlle a wordle like word game to sharpen your brain.",
    keywords: "EdTech, education, technology, AI, teachyaar, tyzlle, qwz4u, tools for learning, english tests"
}

export default Meta
