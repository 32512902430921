import './App.css';

import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Container } from 'react-bootstrap'
import PrivateRoute from './routing/PrivateRoute'

//Screens

import LandingScreen from './screens/LandingScreen'
import LearningScreen from './screens/LearningScreen'

import TermsofUse from './screens/TermsofUse'
import PrivacyPolicy from './screens/PrivacyPolicy'

//Components
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
// import AlertComponent from './components/layout/AlertComponent'



//Articles
import A1_WhatIsAI from './screens/Articles/A1_1WhatIsAI'
import A2_WhatIsGenerativeAI from './screens/Articles/A2_WhatIsGenerativeAI'
import A3_WhatIsDeepLearning from './screens/Articles/A3_WhatIsDeepLearning'
import A4_ShallowVsDeepNeuralNetworks from './screens/Articles/A4_ShallowvsDeepNeuralNetworks'
import A5_ExploringPotentialDeepLearning from './screens/Articles/A5_ExploringPotentialDeepLearning'
import A6_OptimizationDeepLearning from './screens/Articles/A6_OptimizationDeepLearning'
import A7_RegressionAnalysisWithDeepLearning from './screens/Articles/A7_RegressionAnalysisWithDeepLearning'
import A8_GPTRevolutionizingAI from './screens/Articles/A8_GPTRevolutionizingAI'
import A9_TransformerNeuralNetworks from './screens/Articles/A9_TransformerNeuralNetworks'
import A10_ExploringBERT from './screens/Articles/A10_ExploringBERT'
import A11_ExploringVectorDBs from './screens/Articles/A11_ExploringVectorDBs'
import A12_ComplexitiesDeepLearningDeployment from './screens/Articles/A12_ComplexitiesDeepLearningDeployment'
import A13_RetrievalAugmentedGeneration from './screens/Articles/A13_RetrievalAugmentedGeneration'
import A14_GenerativeAIImages from './screens/Articles/A14_GenerativeAIImages'





const App = () => {

  return (

    <Router>

      <Header />

      {/* <AlertComponent /> */}

      <main className='py-3' style={{marginTop:'0px', paddingTop:'0px'}}>

        <Switch>

          <Route path='/' component={LandingScreen} exact /> 

          <Route path='/termsandconditions' component={TermsofUse} exact />
          
          <Route path='/privacypolicy' component={PrivacyPolicy} exact />


          {/* Articles */}
          <Route path='/learn' component={LearningScreen} exact /> 

          <Route path='/learn/what-is-ai' component={A1_WhatIsAI} exact /> 
          <Route path='/learn/what-is-generative-ai' component={A2_WhatIsGenerativeAI} exact /> 
          <Route path='/learn/what-is-deeplearning-the-engine-powering-modern-ai' component={A3_WhatIsDeepLearning} exact /> 
          <Route path='/learn/shallow-vs-deep-neural-networks-understanding-the-differences' component={A4_ShallowVsDeepNeuralNetworks} exact /> 
          <Route path='/learn/exploring-the-vast-potential-of-deep-learning-a-comprehensive-insight' component={A5_ExploringPotentialDeepLearning} exact /> 
          <Route path='/learn/decoding-optimization-in-deep-learning-a-simplified-introduction' component={A6_OptimizationDeepLearning} exact /> 
          <Route path='/learn/regression-analysis-with-deep-learning' component={A7_RegressionAnalysisWithDeepLearning} exact /> 
          <Route path='/learn/exploring-gpt-the-revolutionary-ai-transforming-natural-language-processing' component={A8_GPTRevolutionizingAI} exact /> 
          <Route path='/learn/the-transformer-neural-networks-pioneering-modern-nlp' component={A9_TransformerNeuralNetworks} exact /> 

          <Route path='/learn/exploring-bert-revolutionizing-nlp-with-bidirectional-transformers' component={A10_ExploringBERT} exact /> 
          <Route path='/learn/exploring-vector-databases-revolutionizing-data-storage-in-ai-applications' component={A11_ExploringVectorDBs} exact /> 
          <Route path='/learn/navigating-the-complexities-of-deep-learning-deployment' component={A12_ComplexitiesDeepLearningDeployment} exact /> 
          <Route path='/learn/unveiling-the-power-of-retrieval-augmented-generation-rag' component={A13_RetrievalAugmentedGeneration} exact /> 
          <Route path='/learn/unlocking-creativity-with-generative-ai-a-new-era-of-image-creation' component={A14_GenerativeAIImages} exact /> 


        </Switch>

      </main>

      <Footer />

    </Router>

  );
}

export default App;
