import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
//import { composeWithDevTools } from 'redux-devtools-extension'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';


import {alertReducer} from './reducers/alertReducers'


const reducer = combineReducers({

    alerts: alertReducer,


})



const initialState = {

} 


const middleware = [thunk]

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware));

const store = createStore(reducer, initialState, devTools);


// const store = createStore(
//     reducer, 
//     initialState, 
//     composeWithDevTools(applyMiddleware(...middleware))
// )

export default store