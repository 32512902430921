import React from 'react';
import {Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, userState: {userInfo}, ...rest }) => 
(<Route {...rest} render={props => !userInfo ? (<Redirect to='/login' />) : (<Component {...props} />)} />
)

PrivateRoute.propTypes = {
    userState: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
    userState: state.userState
});

export default connect(mapStateToProps)(PrivateRoute);
