import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'
import im1 from './A3_1.jpg';
import im2 from './A3_2.jpg';

import Meta from '../../components/Meta'


export const A3_WhatIsDeepLearning = ({match, history}) => {

    
    const dispatch = useDispatch()


    useEffect(() => {


    }, [])


    return(

        <>

            <Meta title={"What is Deep Learning: The Engine Powering Modern AI ?"} description={"Uncover the essentials of Deep Learning in our easy-to-understand guide. Learn about its origins, how it works, and its transformative applications across various industries. Perfect for beginners and enthusiasts looking to understand this pivotal AI technology."} keywords={'Deep Learning Basics, AI Technology, Neural Networks, Machine Learning, Deep Learning Applications, AI for Beginners, Understanding AI, Technology Trends, AI Advancements, Deep Learning in AI'} />

            <div style={{margin:'20px', minHeight:'90vh'}}>

                <Container>

      


                <p className='h2' style={{textAlign:'center'}}> What is Deep Learning: The Engine Powering Modern AI  ? </p>

                <img src={im2} style={{height:'20vw', margin:'10px'}}/>



                <p>
                    Deep learning, a subset of machine learning in Artificial Intelligence (AI), is a methodology that teaches computers to learn by example, much like humans do. It is like teaching a computer to recognize patterns and make decisions based on examples, using a series of steps that help it to understand complex information.
                </p>

                <p className='h3'>
                    The Origins of Deep Learning
                </p>

                <p>
                The journey of Deep Learning began in the 1950s with the concept of artificial neurons, which led to the creation of neural networks (by Walter Pitts and Warren McCulloch). However, it wasn't until the advent of the internet and the explosion of data, along with improvements in computing power, that deep learning truly took off in the 21st century. The pivotal moment came when deep neural networks, particularly those with many layers, began achieving remarkable accuracy in tasks such as image and speech recognition.

                </p>

                <img src={im1} style={{height:'20vw', margin:'10px'}}/>

                <p className='h3'>
                Understanding Neurons and Neural Networks

                </p>

                <p>
                The basic building block of Deep Learning is the artificial neuron, which is inspired by the biological neuron in the human brain. In typical cases, a neuron in a neural network takes a set of inputs, performs a dot product, optionally follows it with a non-linear function, and produces an output. When these neurons are stacked together in layers, they form a neural network. The depth of a network, which is a critical aspect of Deep Learning, comes from using many such layers. Hence, the term 'deep' in 'Deep Learning'.

                </p>

                <p className='h3'>
                A Peek into Deep Learning Architectures

                </p>

                <p>
                A simple Deep Learning architecture might consist of an input layer, a few hidden layers, and an output layer. Each layer is made up of neurons that connect to neurons in the previous and following layers. A common architecture is the Convolutional Neural Network (CNN), widely used in image recognition, which includes convolutional layers to process pixel data efficiently.
                </p>

                <p className='h3'>
                Training Deep Learning Models

                </p>

                <p>
                Training a Deep Learning model involves feeding it large amounts of data and allowing it to adjust its internal parameters (weights and biases) through a process called backpropagation. This process uses optimization algorithms, such as gradient descent, to minimize the difference between the actual output and the predicted output of the network, across all the examples in the training data.
                
                </p>

                <p className='h3'>
                Applications of Deep Learning

                </p>

                <p>
                Deep learning has found applications in numerous fields:
                </p>


                <ul>
                    <li>
                    Image and Video Recognition: Used in facial recognition and automated video analysis.
                    </li>
                    <li>
                    Natural Language Processing (NLP): Powers language translation services, chatbots, and digital assistants.
                    </li>
                    <li>
                    Autonomous Vehicles: Enables cars to recognize the space around them and make driving decisions.
                    </li>
                    <li>
                    Healthcare: Assists in diagnosing diseases from medical images.
                    </li>
                    <li>
                    Finance: Detects fraudulent transactions by recognizing patterns in data.
                    </li>
                    <li>
                    Voice Assistants: Powers voice recognition systems like Siri and Alexa.
                    </li>
                </ul>

                <p>
                In conclusion, Deep Learning has become a cornerstone of AI, providing the foundation for many of the sophisticated services we use today. Its ability to learn from vast quantities of data and improve over time makes it an invaluable tool for solving complex problems across various industries. As data grows and computing power increases, Deep Learning is poised to unlock even greater potentials and continue to transform technology and life.
                </p>




            </Container>

            </div>

        
        </>

 



    )


}

export default A3_WhatIsDeepLearning


