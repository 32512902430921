import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'



export const PrivacyPolicy = ({match, history}) => {

    
    const dispatch = useDispatch()


    useEffect(() => {


    }, [])



    return(



        <div style={{marginTop:'0px', minHeight:'90vh'}}>


        <h1>PRIVACY POLICY </h1>

        <h5>Effective date:  Jan 23, 2024 </h5>

        <ListGroup style={{border:'None'}}>

        <ListGroup.Item style={{border:'None'}}>
        Pixagan Technologies Private Limited (“Company”, “We” “Our” or “Us” and their connotations) operates a website (https://www.pixagan.ai/)  (referred to as “Platform”) which is engaged in providing AI Services and providing AI instructional material(“Services”). The users of the Platform are referred to as “You”, as per the context as may be applicable in this Privacy Policy. The term "User(s)" for the purposes of this Privacy Policy shall be read as the end users and shall mean anyone who uses the Platform.
        You must be at least 18 years old to access Our Platform. If you are under 18 years of age (i.e. “a minor”), your usage of the Platform shall be subject to the consent, supervision and moderation by a parent or legal guardian. The Company reserves the right to refuse to provide You with access to the Services if it is discovered that You are under the age of 18 years and the consent to use the products or Services has not been given by your parent/legal guardian. You acknowledge that the Company does not have the responsibility to ensure that You conform to the aforesaid eligibility criteria and that it shall be your sole responsibility to ensure that You meet the same. By your continued access or use of the Platform, You signify your agreement to be legally bound by the Privacy Policy and the Terms (described below) set forth herein. If You do not agree to this Privacy Policy, You are requested to promptly exit this page and stop accessing the Services. 
        The Platform gathers certain information with prior consent from the Users. Any capitalized term used but not defined in this Privacy Policy shall have the meaning attributed to it in Our Terms. 
        Our Users’ privacy is extremely important to Us. We recognize the privacy concerns of the Users and hence as a commitment to provide safe and secure experience on Our Platform, We have formulated this Privacy Policy to help the Users make an informed decision.
        </ListGroup.Item>

        <ListGroup.Item style={{border:'None'}}>
        <h5>LEGAL BASIS:</h5>
        This Privacy Policy (“Privacy Policy") is meant to help the Users understand what information we collect, why we collect it, what information we disclose and how the Users can update, manage, export, and delete their information. It shall be read with the terms and conditions available on https://www.pixagan.ai/termsandconditions (“Terms”) and in compliance with the following:
        <ul>
            <li>
        The Information Technology Act, 2000 and rules thereunder;
        </li>
        <li>
        The Information Technology (Reasonable Security Practices and Procedures and Personal Information) Rules, 2011 (the “IT Rules”); and
        </li>
        <li>
        The Information Technology (Intermediaries Guidelines) Rules, 2011.
        </li>
        </ul>
        This Privacy Policy and the Terms shall be governed by and construed in accordance with the laws of India which are not in conflict with each other. Any disputes arising out of or in connection with this Privacy Policy shall be subject to the exclusive jurisdiction of the competent courts of Kochi, Kerala, India.
        </ListGroup.Item>


        <ListGroup.Item style={{border:'None'}}>
        <h5>CONSENT:</h5>
        The Users will be deemed to have read, understood and agreed to the terms as provided in this Privacy Policy. By granting their assent to this Privacy Policy, the Users provide consent to such use, collection and disclosure of Personal Information as prescribed in this Privacy Policy.
        We reserve the right to update or change our Privacy Policy at any time and the Users should check this Privacy Policy periodically. To inform You of any material change as regards the manner in which We collect or process your information, including changes to this Privacy Policy, our updated Privacy Policy will be posted by Us on the Platform, indicating the date on which the Privacy Policy was updated at the top of the Privacy Policy. We advise the Users to check the Privacy Policy available on the Platform from time to time.Continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute the Users’ acknowledgment of the modifications and their consent to abide and be bound by the modified Privacy Policy. Users have the right to opt out of the Platform and/ or Services at any moment. In addition, the Users can, by sending an email to biz@pixagan.com, inquire whether the Company is in possession of their personal data, and they may also require the Company to delete and destroy all such information, upon request.
        In the event the Services are used on behalf of any other individual, or on behalf of any entity, the Users represent that they are authorised to accept this Privacy Policy and share such data as required on behalf of such person or entity.
        </ListGroup.Item>


        <ListGroup.Item style={{border:'None'}}>
        <h5>INFORMATION COLLECTED:</h5>
        All information collected by the Company is with the Users’ consent. The Company is however free to use, collect, and disclose information of the Users which is in the public domain.
        The Users hereby consent to the collection of certain information (“Personal Information”) by the Company.
        Information collected by the Company shall include but not be limited to the following:
        <ul>
            <li>
        Geolocation of the Users; and
        </li>
        <li>
        Time zone of the Users
        </li>
        </ul>
        We may also collect certain information automatically as You navigate through the Platform (which may include usage details, IP address, device ID and type, your browser type and language, the operating system used by your device, access times, and information collected through cookies, web beacons, UTM parameters, tracking pixels and other tracking technologies).
        </ListGroup.Item>


        <ListGroup.Item style={{border:'None'}}>
        <h5>USE OF THE INFORMATION COLLECTED:</h5>
        We collect Personal Information only necessary for the following purposes:
        <ul>
            <li>
        To provide improved user experience;
        </li>
        <li>
        To enable You to avail of Our Services, and ensure efficient customer care experience and develop new features;
        </li>
        <li>
        To analyze Platform usage, improve the Platform’s content and Service offerings, and customize the Platform’s content, layout, and Services;
        </li>
        <li>
        To provide You with an efficient, safe and customized experience while using the Platform;
        </li>
        <li>
        To analyze overall trends to helps Us improve the Platform and Services;
        </li>
        <li>
        To comply with applicable laws, rules, and regulations;
        </li>
        <li>
        To transfer the data and the rights associated therewith to a third party as part of reorganization or a sale of the assets of any division of the Company or the entire Company itself; and
        </li>
        <li>
        In any other way consistent with this Privacy Policy and to support the above activities.
        Additionally,
        </li>
        <li>
        We reserve the right to use or disclose your Personal Information in response to any statutory or legal requirements in order to protect Our rights, the rights of our other Users, data processors and any other person or property.
        </li>
        <li>
        Finally, We will use or disclose your Personal Information if We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person or property, violations of the Platform’s other policies, or as otherwise required by law when responding to subpoenas, court orders and other legal processes.
        </li>
        </ul>
        </ListGroup.Item>


        <ListGroup.Item style={{border:'None'}}>
        <h5>DATA PROTECTION:</h5>
        The security of Personal Information is important and We have implemented commercially acceptable means to protect the same. However, remember that no method of transmission over the internet, or method of electronic storage, is 100% secure and We cannot guarantee its absolute security. We have implemented best industry practices and security policies, rules and technical measures to protect the data including Personal Information that is under the Company’s control from unauthorised access, improper use or disclosure, unauthorised modification and unlawful destruction or accidental loss. However, for any data loss or theft due to unauthorized access to the User’s electronic devices through which the User avails the Services, We shall not be held liable for any loss whatsoever incurred by the User. In the event, Our partners and third parties distribute Personal Information outside the scope of Our agreement with such partners or third parties, the Company cannot be held liable for the confidentiality. Further, in case breach of security or actions including without limitation, computer hacking, unauthorized access to computer data and storage device, computer crashes etc., you agree that the Company will shall not be held responsible in any manner. 
        </ListGroup.Item>

        <ListGroup.Item style={{border:'None'}}>
        <h5>SHARING OF PERSONAL INFORMATION:</h5>
        The Company may share your Personal Information with third party service providers (called ‘data processors’) to perform certain processing activities on Our behalf, such as Parties involved in hosting and enabling our Services. Please note that we will not share your Personal Information with third parties which may allow them to use this information for their own purposes unless you have given explicit consent thereto. Our third party processors include: Google Analytics.

        We may use third-party service providers to monitor and analyze the use of Our Service. We use remarketing services to advertise on third party websites to You after You visit Our Platform. We and Our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to Our Platform. We may provide the following third party integration services:  Google Drive, Authentication Services for normal and third party authentication, Advertising, Google Analytics, etc. However, We do not sell your Personal Information.
        </ListGroup.Item>

        <ListGroup.Item style={{border:'None'}}>
        <h5>THIRD PARTY DISCLAIMER:</h5>
        The Platform may contain links to other websites. Please note that when Users click on one of these links, they are entering another website over which We have no control and for which We shall bear no responsibility. Often these websites require the User to enter their Personal Information. We encourage the Users to read the terms of use and privacy policies of all such websites as their policies may differ from Our Privacy Policy. Users agree that We shall not be liable for any breach of your privacy of Personal Information or loss incurred by their use of such websites or services. The inclusions or exclusions are not suggestive of any endorsement by the Company of the website or contents of the website. The Users may visit any third party website linked to the Platform at their own risk.
        </ListGroup.Item>

        <ListGroup.Item style={{border:'None'}}>
        <h5>RIGHTS OF THE USERS:</h5>
        As a User, the Company grants You complete control of the information collected. The rights pertaining thereto are enumerated below:
        <ul>
            <li>
        The right to access, update or delete the information We have on the User. This can be done through the User’s account settings available on the Platform.
        </li>
        <li>
        The right of rectification. Users have the right to have their information rectified if that information is inaccurate or incomplete.
        </li>
        <li>
        The right to object and restrict. Users can object and restrict to the processing of their Personal Information. This may however, interfere with the provision of Services to its optimum.
        </li>
        <li>
        The right to data portability. Users have the right to be provided with a copy of the information We have on them in a structured, machine-readable and commonly used format.
        </li>
        <li>
        The right to withdraw consent. Users have the right to withdraw their consent at any time where consent is given pertaining to Personal Information.
        </li>
        <li>
        The right to be informed. Users have the right to be informed about third parties with which their information has been shared.
        </li>
        </ul>
        Please note that Users may be asked to verify identity before responding to such requests. If the Users are unable to perform these actions themselves, please get in touch with the Grievance Officer for assistance.
        </ListGroup.Item>

        <ListGroup.Item style={{border:'None'}}>
        <h5>COOKIE POLICY:</h5>
        A cookie is a small text file stored by a website in a User’s web browser that helps Us in many ways to make your use of the Services more efficient and meaningful to you (“Cookie(s)”). They allow Us to tailor the Service to better match your needs. A session Cookie is stored only in your computer’s working memory and only lasts for your browsing session. When You close all your browser’s windows, or when You shut down your computer, the session Cookie disappears forever. A persistent Cookie remains on your computer after You close your browser so that it can be used by your browser on subsequent visits to the Services. Persistent Cookies stay on your computer until either they expire or are overwritten with newer Cookies, or You manually remove them. Most browsers can be configured not to accept Cookies; however, this may prevent You from having access to some site functions or features.

        The Cookies through which we collect data and How We use Cookies

        Source	Purpose for which we collect data through Cookies
        LocalStorage Cookies	To retain the solution state of the quizzes, tests, puzzles, articles, learning tools that the Users have taken on the Platform.
        YouTube	As we shall be embedding YouTube content onto the Platform, we’ll collect data in order to understand the kind of content being viewed by You, so as to provide You with better recommendations in the future.

        Apart from as mentioned above, We may use Cookies for the following purposes:
        <ul>
            <li>
        To provide and improve the Services;
        </li>
        <li>
        To remember your preferences
        </li>
        <li>
        For analytics and research, including to understand how You use the Services and which contents are preferred by You;
        </li>
        <li>
        To personalize the Services for You; and
        </li>
        <li>
        To assist with security and administrative functions.
        </li>
        </ul>
        What are Your choices about Cookies?
        You may learn more about how Cookies work and how to turn them off, in your particular browsers. Additionally, if you turn your Cookies off, some features of Our Services may not function properly.
        </ListGroup.Item>

        <ListGroup.Item style={{border:'None'}}>
        <h5>CONTACT DETAILS:	</h5>		
        Users may contact the Company by email or postal mail on the following address of the Grievance Officer:

        <p>Company: Pixagan Technologies Private Limited</p>
        <p>Grievance Officer: Anil Variyar </p>
        <p>E-mail ID: biz@pixagan.com	</p>
        <p>Address: 39/2475-B1, Suite#244 </p>
        <p> LR Towers, SJRRA 104, </p>
        <p>S Janatha Road, Palarivattom, </p>
        <p>Kochi, Kerala, 682025 </p>
        </ListGroup.Item>

        </ListGroup>



        </div>



    )


}

export default PrivacyPolicy


