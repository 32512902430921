import {SET_ALERT, REMOVE_ALERT} from '../constants/alertConstants.js';

const initialState = [];

export const alertReducer = (state = {alerts: []}, action) => {

    const {type, payload } = action;

    switch(type){
        case SET_ALERT:
            return {alerts:[payload, ...state.alerts]};
        case REMOVE_ALERT:
            return {alerts : state.alerts.filter(alert => alert.id !== payload)};
        default:
            return state;
    }
}