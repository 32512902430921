import React, { Fragment, useEffect } from 'react' 
import {  useHistory} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import {Navbar, Nav, Container, NavDropdown, Img, Button, DropdownButton, Dropdown, Card } from 'react-bootstrap'

const Header = () => {

    const dispatch = useDispatch()
    const history = useHistory();


    useEffect(() => {


    }, [])

    const navigateToHome = () => {
        history.push('/')
    }


    return (
        <header className="header" style={{border:'None', marginBottom:'0px', borderLeft:'None', borderRight:'None'}}>


            <Navbar expand="lg" collapseOnSelect style={{padding:'0px', borderColor:'#b861fb'}}>
                
                
                <Container>
                <LinkContainer to='/'>

                    <>
                    <Nav.Link onClick={()=>navigateToHome()} style={{backgroundColor:'white', borderRadius:'40px'}}>
                        <img
                        src="/pixagan.png"
                        height="40"
                        className="d-inline-block align-top"
                        alt="Pixagan Logo"
                        style={{marginRight:'10px'}}
                         />
                        </Nav.Link>
                        
                    </>

                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />



                <Navbar.Collapse id="basic-navbar-nav">

                <Nav className="ml-auto">


                    <LinkContainer to='/'>
                        <Nav.Link className='navbarlink'><Button style={{  margin:'1px', paddingTop:'5px', paddingBottom:'5px', borderRadius:'20px', backgroundColor:'white', color:'black',  border:'None', borderColor:'#c2179a'}}><i class="fa fa-home"></i></Button></Nav.Link>
                    </LinkContainer>


                    <LinkContainer to='/learn'>
                        <Nav.Link className='navbarlink'><Button style={{  margin:'1px', paddingTop:'5px', paddingBottom:'5px', borderRadius:'20px', backgroundColor:'white', color:'black',  border:'None', borderColor:'#c2179a'}}>Blog</Button></Nav.Link>
                    </LinkContainer>
            


                </Nav>

            </Navbar.Collapse> 



            </Container>

        </Navbar>

        </header>
    )
}

export default Header