import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'

import Meta from '../components/Meta'

import im1 from './Articles/A1_3.jpg'
import im2 from './Articles/A2_1.jpg'
import im3 from './Articles/A3_2.jpg'
import im4 from './Articles/A4_1.jpg'
import im5 from './Articles/A5_1.jpg'
import im6 from './Articles/A6_1.jpg'
import im7 from './Articles/A7_1.jpg'
import im8 from './Articles/A8_1.jpg'
import im9 from './Articles/A9_1.jpg'
import im10 from './Articles/A10_1.jpg'
import im11 from './Articles/A11_1.jpg'
import im12 from './Articles/A12_1.jpg'
import im13 from './Articles/A13_1.jpg'
import im14 from './Articles/A14_1.jpg'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

export const LearningScreen = ({match, history}) => {

    
    const dispatch = useDispatch()


    useEffect(() => {


    }, [])

    const navigateToArticle = (article_url) => {
        history.push(`/learn/${article_url}`)
    }

    const articles = [
        {
            title:'What is AI ?',
            url:'what-is-ai',
            image:im1,
            summary:"Discover Artificial Intelligence's evolution, current applications, and future potential. Explore AI's impact across industries, from data analysis and image recognition to generative technologies and ethical considerations.",
        },
        {
            title:'What is Generative AI ?',
            url:'what-is-generative-ai',
            image:im2,
            summary:"Explore the world of Generative AI, the groundbreaking technology shaping the future of creativity. Discover how it learns from data to generate new, original content across various mediums, pushing the boundaries of artificial intelligence and opening up a realm of possibilities.",
        },
        {
            title:'What is Deep Learning: The Engine Powering Modern AI ?',
            url:'what-is-deeplearning-the-engine-powering-modern-ai',
            image:im3,
            summary:"Uncover the essentials of Deep Learning in our easy-to-understand guide. Learn about its origins, how it works, and its transformative applications across various industries. Perfect for beginners and enthusiasts looking to understand this pivotal AI technology.",
        },
        {
            title:'Shallow vs. Deep Neural Networks: Understanding the Differences',
            url:'shallow-vs-deep-neural-networks-understanding-the-differences',
            image:im4,
            summary:"Dive into the distinctions between shallow and deep neural networks in our comprehensive guide. Understand their unique architectures, capabilities, and applications in AI, and learn how to choose the right network for your AI challenges.",
        },
        {
            title:'Exploring the Vast Potential of Deep Learning: A Comprehensive Insight',
            url:'exploring-the-vast-potential-of-deep-learning-a-comprehensive-insight',
            image:im5,
            summary:"Dive into the dynamic world of Deep Learning and explore its immense potential. This article provides an insightful overview of how deep learning leverages big data, scales with it, constructs complex architectures, and processes unstructured data, along with its challenges and solutions.",
        },
        {
            title:'Decoding Optimization in Deep Learning: A Simplified Introduction',
            url:'decoding-optimization-in-deep-learning-a-simplified-introduction',
            image:im6,
            summary:"Embark on a journey to understand optimization in deep learning, simplified for the non-technical reader. Learn about the role of optimization in enhancing AI models, the basics of gradient descent, and various optimization algorithms transforming AI's efficiency and accuracy.",
        },
        {
            title:'Regression Analysis with Deep Learning',
            url:'regression-analysis-with-deep-learning',
            image:im7,
            summary:"Get an overview of regression analysis with deep learning in this example. Discover how deep learning transforms regression tasks, making predictions more accurate and insights more profound. From simple linear models to advanced deep learning techniques, learn how AI is revolutionizing predictive analytics.",
        },
        {
            title:'Exploring GPT: The Revolutionary AI Transforming Natural Language Processing',
            url:'exploring-gpt-the-revolutionary-ai-transforming-natural-language-processing',
            image:im8,
            summary:"Dive into the world of Generative Pre-trained Transformers (GPT) with our comprehensive guide. Discover how GPT's unique architecture, spanning from its inception to its latest versions, is revolutionizing natural language processing (NLP). Learn about its applications, training methodologies, and the promising future of AI in understanding and generating human-like text.",
        },
        {
            title:'The Transformer Neural Networks: Pioneering Modern NLP',
            url:'the-transformer-neural-networks-pioneering-modern-nlp',
            image:im9,
            summary:"Dive into the revolutionary world of Transformer Neural Networks and their impact on modern natural language processing (NLP). From their origins to the cutting-edge applications transforming AI, learn how these models are reshaping our approach to understanding and interacting with human language.",
        },
        {
            title:'Exploring BERT: Revolutionizing NLP with Bidirectional Transformers',
            url:'exploring-bert-revolutionizing-nlp-with-bidirectional-transformers',
            image:im10,
            summary:"Dive deep into BERT (Bidirectional Encoder Representations from Transformers), the AI model transforming natural language processing. Understand its origins, unique architecture, and the powerful applications it enables in AI. Learn how BERT's bidirectional training sets a new standard for NLP tasks, from text analysis to improving search engine results.",
        },
        {
            title:'Exploring Vector Databases: Revolutionizing Data Storage in AI Applications',
            url:'exploring-vector-databases-revolutionizing-data-storage-in-ai-applications',
            image:im11,
            summary:"Unlock the potential of vector databases in AI and machine learning. Explore how these specialized databases manage multidimensional data for improved efficiency in NLP, image recognition, and beyond. Learn about their applications, how they work, and the leading tools revolutionizing data storage and retrieval in AI.",
        },
        {
            title:'Navigating the Complexities of Deep Learning Deployment',
            url:'navigating-the-complexities-of-deep-learning-deployment',
            image:im12,
            summary:"Explore the critical challenges of deploying deep learning models, from convergence issues to server failures and model portability. Learn expert strategies for navigating deployment errors, optimizing models, and ensuring robust, efficient AI systems in production environments.",
        },
        {
            title:'Unveiling the Power of Retrieval-Augmented Generation (RAG)',
            url:'unveiling-the-power-of-retrieval-augmented-generation-rag',
            image:im13,
            summary:"Dive into the innovative world of Retrieval-Augmented Generation (RAG) and discover how it's setting new standards in natural language processing. Learn how RAG combines large language models with vector databases to enhance accuracy, reduce hallucinations, and stay up-to-date with real-world information.",
        },
        {
            title:'Unlocking Creativity with Generative AI: A New Era of Image Creation',
            url:'unlocking-creativity-with-generative-ai-a-new-era-of-image-creation',
            image:im14,
            summary:"Dive into the transformative world of Generative AI for image creation. From early GANs to cutting-edge algorithms like Stable Diffusion, discover how AI is revolutionizing art, gaming, fashion, and more by generating stunning visuals from textual descriptions. Explore the applications and future of image generative AI.",
        },
    ]



    return(

        <>
                <Meta title={"Learn the Basics of AI with Pixagan Learn: Master AI Model Building, Mathematics & Science"} description={"Embark on a journey to mastering AI with Pixagan's Learn section. Explore in-depth articles and tutorials on AI, the science and math behind it, and hands-on guides to building AI models. Whether you're a beginner or an advanced learner, our resources are designed to enhance your understanding and skills in the ever-evolving world of artificial intelligence."} keywords={"AI Learning Resources, AI Model Tutorials, Artificial Intelligence Education, AI Mathematics, Science Behind AI, Building AI Models, AI for Beginners, Advanced AI Techniques, Pixagan AI Learning, Machine Learning Guides"} />

                <div style={{marginTop:'0px', minHeight:'90vh'}}>

                <p className='h3' style={{textAlign:'center'}}> Learn the Basics of AI with Pixagan Learn </p>


                <Row>
                    {articles && articles.map((carticle, index)=>(
                        <Col style={{margin:'10px'}}>
                            <Card style={{width:isMobile?'90vw': '30vw' ,marginBottom:'10px', padding:'1px'}} onClick={()=>navigateToArticle(carticle.url)}>
                                <Card.Header>
                                    <p className='h5'>{carticle.title}</p>
                                </Card.Header>
                                <Card.Body>
                                    <img src={carticle.image} style={{width: isMobile? '85vw' :'28vw', margin:'0px'}}/>
                                    
                                    <p style={{marginTop:'10px'}}>{carticle.summary}</p>
                                </Card.Body>
                                

                            </Card>
                        </Col>
                    ))}
                </Row>




</div>
        
        </>





    )


}

export default LearningScreen


