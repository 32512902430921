import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, InputGroup, FormControl, Table } from 'react-bootstrap'


import Carousel from 'react-bootstrap/Carousel';


import Meta from '../components/Meta'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

export const LandingScreen = ({match, history}) => {

    
    const dispatch = useDispatch()


    useEffect(() => {


    }, [])


    const navigateToTeachYaar = () => {

        window.open('https://teachyaar.com', '_blank', 'noopener,noreferrer');
    
    }

    const navigateToTyzlle = () => {

        window.open('https://tyzlle.com', '_blank', 'noopener,noreferrer');
    
    }

    const navigateToQwz4u = () => {

        window.open('https://qwz4u.com', '_blank', 'noopener,noreferrer');
    
    }




    return(

        <>
        
        <Meta title={"Pixagan: Making Education and Fun and Interactive Journey"} description={"Pixagan provides multiple tools and platforms to make learning fun and interactive. Our ecosystem consists of the TeachYaar platform to create interactive and dynamics learning notebooks, Qwz4u an english quiz platform to prepare for tests and exams and Tyzlle a wordle like word game to sharpen your brain."} keywords={"EdTech, education, technology, AI, teachyaar, tyzlle, qwz4u, tools for learning, english tests"} />


        <div style={{marginTop:'0px'}}>

        <section style={{backgroundColor:'#f0f2f2'}}>

            <div style={{textAlign:'center', marginTop:'0px'}} >

                <p className='h1'>Education : A fascinating journey</p>
                <p className='h5'>With TeachYaar tools and platforms.</p>
            </div>

        </section>

        <section class="ai-model"  onClick={()=>navigateToTeachYaar()}>
        <p className='h2'>Create interactive, dynamic courses with <span style={{color:'#7b2ff7'}}>TeachYaar Notebooks</span></p>
        <p className='h5'>Make learning fun for your students.</p>
        <p className='h5' style={{color:'#7b2ff7'}}>https://teachyaar.com</p>

        {isMobile ? (
            <img src="TeachYaar.jpg" alt="TeachYaar Example" style={{width:'90vw'}}/>
        ) : (
            <img src="TeachYaar.jpg" alt="TeachYaar Example" style={{height:'50vh'}}/>
        )}
        
        </section>

        <section class="ai-model" style={{backgroundColor:'#f0f2f2'}} onClick={()=>navigateToTyzlle()}>
            <p className='h2'>Improve your English and Keep your brain sharp with <span style={{color:'#7b2ff7'}}>Tyzlle</span></p>
            <p className='h5'>A crossword-way to learn.</p>
            <p className='h5' style={{color:'#7b2ff7'}}>https://tyzlle.com</p>
            
            {isMobile ? (
                <img src="Tyzlle.jpg" alt="Tyzlle Example" style={{width:'90vw'}}/>
            ) : (
                <img src="Tyzlle.jpg" alt="Tyzlle Example" style={{height:'50vh'}}/>
            )}
        
        </section>

        <section class="ai-model" onClick={()=>navigateToQwz4u()}>
            <p className='h2'>Prepare for English Exams with <span style={{color:'#7b2ff7'}}>Qwz4u</span>, an English-Centric Quiz Platform</p>
            <p className='h5'>Free English Practice Quizzes at your fingertips.</p>
            <p className='h5' style={{color:'#7b2ff7'}}>https://qwz4u.com</p>
            
            {isMobile ? (
                <img src="Qwz4up.jpg" alt="Qwz4u Example"  style={{width:'90vw'}}/>
            ) : (
                <img src="Qwz4up.jpg" alt="Qwz4u Example"  style={{height:'50vh'}}/>
            )}
        
        </section>



        <section class="ai-model" style={{backgroundColor:'#f0f2f2'}}>
        <Link to='/learn'>
            <p className='h2'>Unravel the mysteries of AI with our Blog</p>
        
            

            <p className='h5'>A quick read on the basic concepts and new developments in a fun manner.</p>
            

            {isMobile ? (
                <img src="ImageRecognition.png" alt="A representation of AI" style={{width:'90vw'}}/>
            ):(
                <img src="ImageRecognition.png" alt="A representation of AI" style={{height:'50vh'}}/>
            )}
            
            </Link>

        </section>




</div>







        </>

 



    )


}

export default LandingScreen


